<template>
  <div v-if="canDo('credpal:can_view_secured_investments')">
    <div class="border border-solid border-blue-200 pt-4">
      <datatable
        :data="investments.data"
        :columns="investments.columns"
        :filters="investments.filters"
        :query="investments.query"
        :loading="investments.loading"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/invest/admin/investments/credit-builder-investments`"
        :on-click="click"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="investments.query"
            class="mb-5"
            placeholder="Search for user's name, investment's name,id"
            @submit="reloadTable"
          />

          <div class="flex items-center mb-5">
            <template v-for="(filter, filterIndex) in investments.filters">
              <div
                :key="filterIndex"
                class="processor-filter-item"
                :class="{ active: currentFilter === filter }"
                @click.prevent="setFilter(filter)"
              >
                {{ filter.title }}
              </div>
            </template>
          </div>
        </template>
      </datatable>
    </div>

    <!-- <modals-user
      :selected-user="users.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentFilter: null,
      investments: this.$options.resource([], {
        query: '',
        filters: [
          { name: 'all', title: 'All' },
          { name: 'active', title: 'Active' },
          { name: 'liquidated', title: 'Liquidated' },
          { name: 'withdrawn', title: 'Withdrawn' },
          { name: 'reinvested', title: 'Reinvested' },
          { name: 'closed', title: 'Closed' },
        ],
        selected: null,
        columns: [
          {
            name: 'user_id',
            th: 'User Id',
          },
          {
            name: 'user_name',
            th: 'Name',
            // render: investment =>
            //   `${investment?.user?.name} ${investment?.user?.last_name || 'N/A'}`
          },
          {
            name: 'id',
            th: 'Invest Id',
          },
          {
            name: 'name',
            th: 'Investment Name',
          },
          {
            name: 'amount',
            th: 'Amount',
            render: (investment) =>
              `₦ ${this.$options.filters.currency(investment.amount)}`,
          },
          {
            name: 'interest',
            th: 'Interest',
            render: (investment) =>
              `₦ ${this.$options.filters.currency(investment.earnings)}`,
          },
          {
            name: 'days',
            th: 'Days',
          },
          {
            name: 'pnd',
            th: 'PND',
            render: (investment, pnd) => this.pndStatusCheck(pnd),
          },
          {
            name: 'percentage',
            th: 'Percentage',
          },
          {
            name: 'tax',
            th: 'Tax',
          },
          {
            name: 'status',
            th: 'Status',
            render: (investment, status) => this.investmentStatusCheck(status),
          },
          {
            name: 'active_at',
            th: 'Active At',
            render: (investment, date) =>
              this.$moment(date).format('MMM Do YYYY'),
          },
          {
            name: 'closing_at',
            th: 'Closing Date',
            render: (investment, date) =>
              this.$moment(date).format('MMM Do YYYY'),
          },
        ],
      }),
    };
  },
  methods: {
    click(row) {
      window.open(
        this.$router.resolve({
          name: 'staff-view',
          params: {
            staffId: row?.user_id,
          },
        }).href,
        '_blank'
      );
    },
    userDeleted() {
      this.reloadTable();
    },
    userModalClosed() {
      this.users.selected = null;
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    setFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
    pndStatusCheck(pnd) {
      switch (pnd) {
        case 1:
          return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-2 py-1 inline-block">
            active
          </div>`;
        case 0:
        default:
          return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-2 py-1 inline-block">
              inactive
            </div>`;
      }
    },
    investmentStatusCheck(status) {
      switch (status) {
        case 'closed':
          return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-2 py-1 inline-block">
              ${status}
            </div>`;
        case 'liquidated':
          return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-2 py-1 inline-block">
              ${status}
            </div>`;
        case 'running':
        default:
          return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-2 py-1 inline-block">
              ${status}
            </div>`;
      }
    },
  },
};
</script>
